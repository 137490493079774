import { configureStore, combineReducers } from '@reduxjs/toolkit'
import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer, persistStore } from 'redux-persist'
import changeState from './slices/chagestate'
import ImportSlice from './slices/dashboard/import'
import InventorySlice from './slices/dashboard/inventory'
import loginSlice from './slices/login.slice'
import suppliersbycountriesSlice from './slices/CompetitorAnalysis/suppliersbycountries.slice'
import filterSlice from './slices/vcn/filter.slice'
import productnetworkSlice from './slices/vcn/productnetwork.slice'
import suppliernetworkSlice from './slices/vcn/suppliernetwork.slice'
import overallexposureSlice from './slices/vcn/overallexposure.slice'
import productSlice from './slices/vcn/product.slice'
import providerLeftChartSlice from './slices/CompetitorAnalysis/providerleftchart.slice'
import providerRightChartSlice from './slices/CompetitorAnalysis/providerrightchart.slice'
import supplierDetailsAndExposuresSlice from './slices/SupplierScoreCard/supplierdetailsandexposures.slice'
import productBySuppliersSlice from './slices/SupplierScoreCard/productbysuppliers.slice'
import alternativeSuppliersByProductSlice from './slices/SupplierScoreCard/alternativesuppliersbyproduct.slice'
import individualSupplierInformationSlice from './slices/SupplierScoreCard/individualSupplierInformation.slice'
import competitorAnalysisFiltersSlice from './slices/CompetitorAnalysis/competitorAnalysisFilters.slice'
import KPIVCNSlice from './slices/vcn/kpis.slice'
import supplierconcentration from './slices/vcn/supplierconcentration.slice'
import ShipperSlice from './slices/vcn/shipperid.slice'
import shipperFiltersSlice from './slices/vcn/shipperFilters.slice'
import topcountriesdonutSlice from './slices/CompetitorAnalysis/topcountriesdonut.slice'
import topRegionsbyCompany from './slices/CompetitorAnalysis/topregionsbycompany.slice'
import supplierdetailsSlice from './slices/vcn/supplierdetails.slice'
import externalRoutingKpiSlice from './slices/DigitalTwin/externalrouting.slice'
import internalRoutingKpiSlice from './slices/DigitalTwin/internalrouting.slice'
import productByCategorySlice from './slices/dashboard/productcategory.slice'
import internalRoutingMapSlice from './slices/DigitalTwin/internalroutingmap.slice'
import externalRoutingMapSlice from './slices/DigitalTwin/externalroutingmap.slice'
import topselectionsupplierSlice from './slices/topselection'
import countrylistSlice from './slices/countrylist'
import riskselectionSlice from './slices/Config/riskselection.slice'
import excelUploadSlice from './slices/Config/datamanageexcel.slice'
import updateRiskSlice from './slices/Config/updaterisk.slice'
import drillDownTableSlice from './slices/SupplierScoreCard/drilldowntable.slice'
import templateLibrariesSlice from './slices/libraries/template/template.slice'
import machineLibrariesSlice from './slices/libraries/machine/machine.slice'
import conversionLibrariesSlice from './slices/libraries/conversion/conversion.slice'
import commoditySlice from './slices/inputcostradar/commodity.slice'
import commodityLibrariesSlice from './slices/libraries/commodity/libcommodity.slice'
import dutytariffLibrariesSlice from './slices/libraries/dutyandtariff/dutyandtariff.slice'
import selectedSuppliersSlice from './slices/rfi-rfp/selectedsuppliers.slice'
import sendMailSlice from './slices/rfi-rfp/sendmail.slice'
import inboxSlice from './slices/rfi-rfp/inbox.slice'
import projectDetailsSlice from './slices/rfi-rfp/projectdetails.slice'
import mailBodySlice from './slices/rfi-rfp/mailbody.slice'
import templateDetailsSlice from './slices/rfi-rfp/templates.slice'
import allTemplateDetailsSlice from './slices/rfi-rfp/alltemplates.slice'
import supplierSearchFilterSlice from './slices/rfi-rfp/supplierSearchFilter.slice'
import supplierinformationSlice from './slices/rfi-rfp/supplierinformation.slice'
import projectFilterSlice from './slices/rfi-rfp/filter.slice'
import BasketSlice from './slices/inputcostradar/basket.slice'
import shouldcostSlice from './slices/ice/shouldcost.slice'
import modifyshouldcost from './slices/ice/modifyshouldcost.slice'
import materialSlice from './slices/Config/buildshouldcost/material.slice'
import summaryChartSlice from './slices/ice/summaryChart.slice'
import summaryTableSlice from './slices/ice/summaryTable.slice'
import supplierCountSlice from './slices/rfi-rfp/supplierCount.slice'
import { updateProjecDataSlice } from './slices/othercostslice/otherCost.slice'
import alternateSuppliersSlice from './slices/SupplierScoreCard/alernatesuppliers.slice'
import attachmentSlice from './slices/rfi-rfp/attachment.slice'
import countriesDataSlice from './slices/libraries/machine/countries.slice'
import createmachineSlice from './slices/libraries/machine/createmachine.slice'
import iceoverviewSlice from './slices/ice/overview.slice'
import iceoverviewfilterSlice from './slices/ice/overviewfilter.slice'
import barchartSlice from './slices/ice/barchart.slice'
import piechartSlice from './slices/ice/piechart.slice'
import buildshouldcostdetailsSlice from './slices/ice/buildshouldcostdetails/buildshouldcostdetails.slice'
import vendorChartSlice from './slices/ice/vendorNetwork.slice'
import ManageMentReportSlice from './slices/ManageMentReport/ManageMentReport.slice'
import scenarioComparisionSlice from './slices/ice/scenariocomparision/scenariocomparision.slice'
import threadMailSlice from './slices/rfi-rfp/threadmail.slice'
import statusSlice from './slices/rfi-rfp/status.slice'
import statusLineSlice from './slices/rfi-rfp/statusline.slice'
import monitorResponseKipsSlice from './slices/rfi-rfp/kipsmonitor.slice'
import emailTablesSlice from './slices/rfi-rfp/tableemails.slice'
import otherCostOverheadMarginSlice from './slices/ice/buildshouldcostdetails/othercosts/overheadmargin.slice'
import bomSpecTabSlice from './slices/ice/buildshouldcostdetails/BOMSpecTab/bomSpecTab.slice'
import scoreCardKPIsSlice from './slices/SupplierScoreCard/scorecardkpis.slice'
import scoreCardlineChartDataSlice from './slices/SupplierScoreCard/scorecardlinechart.slice'
import otherCostLogisticsSlice from './slices/ice/buildshouldcostdetails/othercosts/logistics.slice'
import competitorTableSlice from './slices/CompetitorAnalysis/competitorTable.slice'
import massUploadSlice from './async/ice/massUpload.slice'
import mailSetSlice from './slices/rfi-rfp/mailset.slice'
import mailStatusSlice from './slices/rfi-rfp/currentStatus.slice'
import pieChartDataSlice from './slices/rfi-rfp/piechart.slice'
import formulaitemSlice from './slices/ice/buildshouldcostdetails/formulaitem.slice'
import projectfiltermailSlice from './slices/rfi-rfp/projectfiltermail.slice'
import productcategorySlice from './slices/vcn/productcategory.slice'
import productsbycategorySlice from './slices/vcn/productsbycategory.slice'
import supplierInfomationAPSlice from './slices/rfi-rfp/supplierinformationap.slice'
import shouldCostPoSlice from '../redux/slices/vcn/shouldcostpoSlice'
import shouldCostPoFilterSlice from './slices/vcn/shouldCostPoFilterSlice'
import supplierCountByCategory from './slices/rfi-rfp/supplierCountByCategory.slice'
import bomAnalysisTreeDataSlice from './slices/bomAnalysis/hierarchydata.slice'
import aiSupplierinformationSlice from './slices/rfi-rfp/aiSupplierinformation.slice'
import mailInboxSlice from './slices/rfi-rfp/mailInbox.slice'
import ProjectNameSlice from './slices/rfi-rfp/projectdata.slice'
import projectwise_inboxSlice from './slices/rfi-rfp/projectwiseinbox.slice'
import getRiskScoresSlice from './slices/vcn/getRiskScores.slice'

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['buildShouldCostDetails','shipperid'],
}

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    template: changeState,
    inventoryanalysis: InventorySlice,
    importanalysis: ImportSlice,
    login: loginSlice,
    suppliersbycountries: suppliersbycountriesSlice,
    vcnfilter: filterSlice,
    productcategory: productcategorySlice,
    productsbycategory: productsbycategorySlice,
    productNetwork: productnetworkSlice,
    supplierNetwork: suppliernetworkSlice,
    overallExposure: overallexposureSlice,
    products: productSlice,
    providerLeftChart: providerLeftChartSlice,
    providerRightChart: providerRightChartSlice,
    supplierDetailsAndExposures: supplierDetailsAndExposuresSlice,
    productBySuppliers: productBySuppliersSlice,
    alternateProductBySuppliers: alternativeSuppliersByProductSlice,
    individualSupplierInformation: individualSupplierInformationSlice,
    competitoranalysisfilters: competitorAnalysisFiltersSlice,
    kpivcn: KPIVCNSlice,
    supplierconcentration: supplierconcentration,
    shipperid: ShipperSlice,
    shipperFilters:shipperFiltersSlice,
    topcountriesdonut: topcountriesdonutSlice,
    topregionsbycompany: topRegionsbyCompany,
    supplierdetails: supplierdetailsSlice,
    internalRoutingKpi: internalRoutingKpiSlice,
    externalRoutingKpi: externalRoutingKpiSlice,
    productByCategory: productByCategorySlice,
    internalRoutingMap: internalRoutingMapSlice,
    externalRoutingMap: externalRoutingMapSlice,
    topselection: topselectionsupplierSlice,
    allcountrylist: countrylistSlice,
    riskselection: riskselectionSlice,
    updaterisk: updateRiskSlice,
    excelupload: excelUploadSlice,
    drillDownTable: drillDownTableSlice,
    templateLibraries: templateLibrariesSlice,
    machineLibraries: machineLibrariesSlice,
    conversionLibraries: conversionLibrariesSlice,
    ////////////////////////////////////////////
    Commodity: commoditySlice,
    Basket: BasketSlice,
    commodityLibraries: commodityLibrariesSlice,
    dutytariffLibraries: dutytariffLibrariesSlice,
    selectedSuppliers: selectedSuppliersSlice,
    sendMail: sendMailSlice,
    inbox: inboxSlice,
    projectDetails: projectDetailsSlice,
    projectFilter: projectFilterSlice,
    mailBody: mailBodySlice,
    templates: templateDetailsSlice,
    allTemplates: allTemplateDetailsSlice,
    supplierSearchFilter: supplierSearchFilterSlice,
    supplierInformation: supplierinformationSlice,
    shouldcost: shouldcostSlice,
    modifyCost: modifyshouldcost,
    material: materialSlice,
    summaryChart: summaryChartSlice,
    summaryTable: summaryTableSlice,
    supplierCount: supplierCountSlice,
    updateProjec: updateProjecDataSlice,
    alernateSuppliers: alternateSuppliersSlice,
    attachment: attachmentSlice,
    countriesData: countriesDataSlice,
    createmachine: createmachineSlice,
    iceoverview: iceoverviewSlice,
    iceoverviewfilter: iceoverviewfilterSlice,
    barChart: barchartSlice,
    pieChart: piechartSlice,
    vendorChart: vendorChartSlice,
    buildShouldCostDetails: buildshouldcostdetailsSlice,
    ManageMentReport: ManageMentReportSlice,
    threadMail: threadMailSlice,
    status: statusSlice,
    statusLine: statusLineSlice,
    monitorKips: monitorResponseKipsSlice,
    monitorPieChart: pieChartDataSlice,
    emailTable: emailTablesSlice,
    scenarioComparision: scenarioComparisionSlice,
    otherCostOverheadMargin: otherCostOverheadMarginSlice,
    billofMaterialSpecifications: bomSpecTabSlice,
    scoreCardKPIs: scoreCardKPIsSlice,
    competitorTable: competitorTableSlice,
    scoreCardLineChart: scoreCardlineChartDataSlice,
    otherCostLogistics: otherCostLogisticsSlice,
    massUploadSlice: massUploadSlice,
    mailSet: mailSetSlice,
    mailStatus: mailStatusSlice,
    formulaitem: formulaitemSlice,
    projectMailFilter: projectfiltermailSlice,
    supplierInformationAP: supplierInfomationAPSlice,
    shouldCostPoFilterSlice: shouldCostPoFilterSlice,
    shouldCostPoSlice: shouldCostPoSlice,
    supplierCountByCategory: supplierCountByCategory,
    bomAnalysisTreeData: bomAnalysisTreeDataSlice,
    aiSupplierInformation: aiSupplierinformationSlice,
    mailInbox: mailInboxSlice,
    projectname: ProjectNameSlice,
    projectwiseinbox: projectwise_inboxSlice,
    getRiskScores:getRiskScoresSlice
  }),
)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
})

export const persistor = persistStore(store)

export default store
