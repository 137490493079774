/* eslint-disable react-hooks/rules-of-hooks */
import { msalInstance } from 'src'
import axios from 'axios'
import { clearAllStorages, navigateHome, setTokensInLocalStorage } from 'src/API/Actions'
import config from '../config/config'

//  ! Removing Cancel Token Since It Is Deprecated.
// const cancelTokenSource = axios.CancelToken.source()

// * Signal Method To Implement the Abort Action
const abortController = new AbortController()
const signal = abortController.signal

const axiosclient = axios.create({
  headers: {
    Authorization: `Bearer ${localStorage.getItem('accesstoken')}`,
  },
  baseURL: config.API_URL,
  // cancelToken: cancelTokenSource.token,
  signal: signal
})

axiosclient.interceptors.request.use(
  (request) => {
    request.headers.Authorization = `Bearer ${localStorage.getItem('accesstoken')}`
    // let v = request.headers.Authorization.split(' ')
    // if (v[1] === 'null') {
    //   request.headers.Authorization = `Bearer ${localStorage.getItem('accesstoken')}`
    // }
    return request
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  },
)

const MAX_SILENT_LOGIN_ATTEMPTS = 3

let silentLoginAttempts = 0

const responseInterceptor = axiosclient.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    // *Return For Aborted Calls
    if (error.name === 'CanceledError') {
      // Request Was Aborted , Nothing to be done
      return
    }
    const status = parseInt(error?.statusCode || error?.status || error?.response?.status)
    switch (status) {
      case 401:
      case 403:
        // debugger
        cancelAllRequests()
        alert('Session Expired. Please Login Again.');
        navigateHome()
        // await attemptSilentLogin()
        break
      default:
        // console.log('Error:', error)
        return Promise.reject(error)
    }
  },
)

export const AxiosClient = async (type, api, payload, toolkit) => {
  try {
    const requestConfig = {
      url: api,
      method: type,
      data: payload,
      // cancelToken: cancelTokenSource.token,
      signal: signal,
    }

    const response = await axiosclient(requestConfig)

    if (response.status === 200 || response.status === 201) {
      return toolkit.fulfillWithValue(response.data)
    } else {
      return toolkit.rejectWithValue(response.Data)
    }
  } catch (error) {
    return toolkit.rejectWithValue(error.message)
  }
}

export const cancelAllRequests = () => {
  // cancelTokenSource.cancel('All requests cancelled by the user.')
  abortController.abort()
  console.log('Request aborted')
}

const attemptSilentLogin = async () => {
  try {
    // Check if the user is logged in
    const account = msalInstance.getActiveAccount()
    // * If Account Exists Then Silent Login
    if (account) {
      console.log(account)
      //* Attempt silent login
      try {
        while (silentLoginAttempts < MAX_SILENT_LOGIN_ATTEMPTS) {
          // let refreshToken = localStorage.getItem('refreshtoken')
          // const loginResponse = await msalInstance.ssoSilent({
          // const loginResponse = await msalInstance.acquireTokenByRefreshToken({
          const loginResponse = await msalInstance.acquireTokenSilent({
            scopes: ['user.read'],
            account: account,
            // refreshToken: refreshToken,
            // tokenQueryParameters: refreshToken,
          })
          console.log('Silent login successful!', loginResponse)
          await setTokensInLocalStorage().then(() => {
            window.location.reload()
          })
          // return axiosclient.request(error.config)
        }
      } catch (error) {
        console.log('Silent login failed!', error)
      }
    }
    // * Else Prompt Login
    else {
      // * Attempt Prompt Login Here
      try {
        const loginResponse = await msalInstance.loginPopup()
        console.log('Re-Login successful!', loginResponse)
        msalInstance.setActiveAccount(loginResponse.account)
        await setTokensInLocalStorage().then(() => {
          window.location.reload()
        })
      } catch (error) {
        console.log('Prompt login failed!', error)
        throw error
      }
    }
  } catch (error) {
    console.log('Re-Login failed!', error)
    // Navigate to login page if silent & prompt login fails
    navigateHome()
  }
}
